
  import { defineComponent, reactive, onBeforeMount, ref } from 'vue';
  import { vuex } from '@/store';
  import { useRouter } from 'vue-router';
  import { useToast } from "vue-toastification";

  import AppBreadCrumbs from '@/components/AppBreadCrumbs.vue';
  import ChildForm from '@/views/children/components/ChildForm.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IChildForm, IChild, ISchool, ISchoolRes } from '@/types';
  import { routesNames } from '@/router';
  import { childrenService, schoolService } from '@/services';
  import { SCHOOLS_PARAMS } from './api-params';

  export default defineComponent({
    name: 'AddChild',

    components: { AppBreadCrumbs, ChildForm, UnsavedChangesModal },

    setup() {
      const router = useRouter();
      const toast = useToast();
      const state: any = reactive({ schools: [] as ISchool[] });
      const childForm = reactive<IChildForm>({
        id: '',
        birthMonthDate: '',
        firstName: '',
        lastName: '',
        studentId: '',
        nickname: '',
        school: {},
        grade: {},
        otherSchoolName: '',
        day: '',
        month: {},
        year: ''
      });
      const disabledHandleChanges = ref<boolean>(false);

      async function onSubmit(payload: IChild) {
        vuex.setLoading(true);

        return await childrenService.createChild(payload)
          .then((res: IChild) => {
            disabledHandleChanges.value = true;
            toast.success('The child was successfully created');
            router.push({ name: routesNames.children, query: { id: res.id } });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchSchools() {
        vuex.setLoading(true);

        return await schoolService.fetchSchools(SCHOOLS_PARAMS)
          .then((res: ISchoolRes) => { state.schools = [...res.data, { name: 'Other' }]; })
          .catch((err: any) => toast.error(err.message))
          .finally(() => vuex.setLoading(false));

      }

      onBeforeMount(async () => { await fetchSchools(); });

      return { state, childForm, disabledHandleChanges, onSubmit };
    }

  });
